.Toolbar {
    height: 36px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    background-color:rgba(0,0,0,0.0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 auto;
    box-sizing: border-box;
    z-index: 101;
    color: white;
}

.Toolbar nav {
    height: 100%;
}

@media (max-width: 500px) {

    .Toolbar {
        padding: 20px 0;
    }

}