@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes movingbox {

    0% {
        left: 90%;
    }
    50% {
        left: 10%;
    }
    100% {
        left: 90%;
    }
}


@keyframes moveboxHorizon {
    0% {
        top: 70%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 50%;
    }
}

.box1 {
    height: 60%;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Source Code Pro', monospace;
}

.box1 {
    -webkit-animation: moveboxHorizon 2s;
}

.form {
    height: 60%;
    width: 100%;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    font-family: 'Source Code Pro', monospace;
}

.TextDisplay p {
    color: #822222;;
    font-family: 'Source Code Pro', monospace;
    font-size: small;
    font-weight: 700;
    text-align: center;
}

.TextDisplay h4 {
    color:  white;
    font-family: 'Source Code Pro', monospace;
    font-size: small;
    font-weight: 700;
    text-align: center;
}

.TextDisplay h5 {
    color:  #808081;
    font-family: 'Source Code Pro', monospace;
    font-size: small;
    font-weight: 700;
    text-align: center;
}

.TextDisplay {
    width: 100%;
    margin: auto;
    align-items: center;
    display: inline-grid;
    column-gap: 10px;
    overflow: scroll;
    max-height: 100%;
}

.TextDisplay::-webkit-scrollbar {
    display: none;
}

.gridItem {
    text-align: center;
    margin: 0 auto;
}

.Title {
    font-weight: 700;
    width: auto;
    background-color: rgb(245, 222, 179, 0.3);
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1%;
}

.NavigationItem {
    padding-top: 20px;
    align-items: center;
    list-style: none;
    margin: 0 auto;
}

.NavigationItem img {
    height: 25px;
    width: auto;
    margin: 2px;
    align-items: center;
}

.aboutMeImg {
    height: 195px;
    width: auto;
    margin: auto;
    margin-top: 10%;
    border-bottom: 12px solid rgba(0, 0, 0, 0.2);
    display: block;
}

.aboutParagraph {
    position: relative;
    margin: auto;
    width: fit-content;
}

.aboutParagraph div {
    position: relative;
    display: table;
    margin: 0;
}

.aboutParagraph p{
    font-family: 'Source Code Pro', monospace;
    color: #808081;
    display: block;
    text-align: justify;
    font-size: small;
    display: table-caption;
    caption-side: bottom;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
}

.boxDisplay {
    height: 60%;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    overflow-x: hidden;
}

.boxDisplay {
-webkit-animation: moveboxHorizon 2s;
/* Hide scrollbar for IE, Edge and Firefox */
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.success {
    position: relative;
    top: 50%;
}

.success img {
    display: block;
    margin: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.boxDisplay::-webkit-scrollbar {
    display: none;
}
a {
    cursor: cell;
}
.years {
    position: relative;
    bottom: 52%;
    display: block;
    margin: 0;
    font-size: small;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1%;
    font-family: 'Source Code Pro', monospace;
    color: #808081;
    cursor: cell;
}

@media (min-width: 768px) {
    .TextDisplay {
        position: relative;
        margin: 0 auto;
        width: 75%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: 150px 150px 150px;
        column-gap: 20px;
        overflow: initial;
    }

    .TextDisplay p {
        font-size: large;
        font-weight: 700;
        text-align: center;
    }

    .box1 {
        position: absolute;
         /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .box1::-webkit-scrollbar {
        display: none;
    }

    .NavigationItem img {
        height: 45px;
        width: auto;
    }

    .boxDisplay {
        height: 80%;
    }

    .aboutMeImg {
        height: 500px;
        margin: 10px auto;
    }

    .aboutParagraph  p{
        width: 100%;
        position: inherit;
        font-size: medium;
    }

    .form {
        height: 60%;
        width: 100%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
        font-family: 'Source Code Pro', monospace;
    }

    .success {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translateX(-50%, -50%);
    }

    .success img {
        display: block;
        margin: auto;
    }

    .disappear {
        display: none;
    }

    .appear {
        display: block;
        min-width: 50%;
    }

    .years {
        position: relative;
        top: 70%;
        left: 45%;
        display: block;
    }
}