.SideItem {
    flex-direction: column-reverse;
    box-sizing: border-box;
    flex: 1 1 100px;
    margin: 5px 5px;
}

.SideItem p {
    text-decoration: none;
    writing-mode:horizontal-tb;
    box-sizing: border-box;
    color: #808081;
}

a {
    all: unset;
}

@media (min-width: 768px) {
    .SideItem {
        margin: auto 0;
        flex-direction: column-reverse;
        box-sizing: border-box;
        flex: 1 1 100px;
        padding: 20px 10px;
    }

    .SideItem p {
        text-decoration: none;
        text-orientation:mixed;
        box-sizing: border-box;
        transform: rotate(-90deg);
        /* color: #B22222; */
        z-index: 300;
        /* border: solid #5B6DCD 1px; */
    }
}