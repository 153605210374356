.InputElement {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    outline: none;
    color: white;
    text-align: left !important;
    background-color: rgba(0,0,0,0.4);
    font: small !important;
    padding: 3px 5px;
    box-sizing: border-box;
    border-radius: 6px;
    display: block;
    border: none;
    font-size: small;
    margin-bottom: 2px;
    border: 1px dotted rgba(178, 34, 34, 0.5);
    width: 85%;
}

.InputElement:focus {
    outline: none;
    background-color: rgba(178, 34, 34, 0.5);
    /* font-size: 1em; */
}

.InputElement:active {
    font-size: initial;
}

button {
    position: relative;
    top: 0;
    left: 40%;
    margin-top: 10%;
    color: grey;
    background-color: rgba(0,0,0,0.2);
    width: 20%;
    font-size: small !important;
    padding: 15px;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    font-family: 'Source Code Pro', monospace;
}

button:hover {
    background-color: rgba(178, 34, 34, 0.5);
}


@media (min-width: 768px) {

    .Label {
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
    }

    .InputElement {
        outline: none;
        color: white;
        text-align: left !important;
        background-color: rgba(0,0,0,0.4);
        font: inherit;
        font-weight: 500;
        text-align: center;
        padding: 6px 10px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        display: block;
        border: none;
    }

    .InputElement:focus {
        outline: none;
        background-color: rgba(178, 34, 34, 0.5);
    }
}