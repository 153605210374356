@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
	
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display:none;
	}
}

.Backdrop {
    height: 100%;
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
}

.fadeIn {
	/* opacity: 1; */
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

.fadeOut {
	/* opacity: 0; */
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: 1s;
}
