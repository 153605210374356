.SideMenu {
    position: relative;
    top: 0;
    left: 0;
    height: 20%;
    width: 100%;
    z-index: 101;
    background-color: rgb(0,0,0,0);
    display: flex;
    font-family: 'Source Code Pro', monospace;
    font-size: small;
}

.SideMenu :hover{
    color:  #B22222;
}

.SideMenu ul {
    margin: auto auto;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    padding: 0 0;
    cursor: crosshair;
}



@media (min-width: 768px) {
    .SideMenu ul {
        width: 10px;
        padding: auto 40px;
        margin: 0 auto;
    }

    .SideMenu {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 10%;
        z-index: 100;
        background-color: rgb(0,0,0,0);
        display: flex;
        font-family: 'Source Code Pro', monospace;
        font-size: large;
        /* margin: auto; */
    }

    .SideMenu :hover{
        color:  #B22222;
    }

    .SideMenu ul {
        margin: auto 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 10px;
        padding: 0 25px;
    }
}