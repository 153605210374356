
 .reactPlayer video {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 102%;
    height: auto !important;
    width: auto !important;
    display: block;
    overflow: hidden;
    background-color: black;
    /* padding-top: 1%; */
  }

.playerWrapper::-webkit-scrollbar{
  display: none;
}

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}

 @media (min-width: 768) {
  
  .reactPlayer {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    height: auto !important;
    width: auto !important;
    display: block;
    margin: auto;
  }
 }

