.NavigationItems {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: auto;
}

@media (min-width: 796px) {
    .NavigationItems {
        width: 360px;
    }
}
