.NavigationItem {
    padding-top: 20px;
    align-items: center;
    list-style: none;
    margin: 0 auto;
    flex: 1 1 20px;
}

.NavigationItem img {
    height: 20px;
    width: auto;
    margin: 2px;
    align-items: center;
}

.NavigationItem:hover {
    display:inline;
    /* opacity: 0.2; */
    /* background: white; */
}

.NavigationItem a:active {
    background-color: transparent;
}


@media (min-width: 768px) {

    .NavigationItem img {
        height: 25px;
        width: auto;
        }

}