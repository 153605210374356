.Logo {

}

.Logo img {
    height: 50px;
    width: auto;
    position: fixed;
    background-color: transparent;
    bottom: 0;
    right: 0;
    z-index: 100;
    margin-bottom: 10px;
    z-index: 101;
    /* margin-left: 100px; */
}
